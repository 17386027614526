/* Change the text color of the date range */
.rdrDayInRange span,
.rdrDayInRange:hover span,
.rdrDayStartPreview span,
.rdrDayStartPreview:hover span,
.rdrDayEndPreview span,
.rdrDayEndPreview:hover span {
  color: white; /* Your desired text color */
}

.rdrDateRangeWrapper,
.rdrDateDisplayWrapper,
.rdrDateDisplay,
.rdrDayDisabled,
.rdrDateInput,
.rdrDateDisplayItem,
.rdrDateDisplayItemActive .calendarElement {
  background-color: rgb(33, 35, 41);
}
.rdrCalendarWrapper {
  background-color: rgb(33, 35, 41);
  width: 100%;
}
.rdrMonthPicker select,
.rdrYearPicker select {
  border: 1px solid rgba(247, 247, 247, 0.1);
  border-radius: 5px;
  text-align: center;
  background-color: rgb(33, 35, 41);
  overflow-y: auto;
  color: white;
}
.rdrMonthPicker select:hover,
.rdrYearPicker select:hover {
  border: 1px solid #0080ff;
  border-radius: 5px;
  text-align: center;
  background-color: rgb(33, 35, 41);
  overflow-y: auto;
  color: white;
}
.rdrMonth .rdrDays,
.rdrDayNumber span,
.rdrDateDisplay span input {
  color: white;
}
.inputBox {
  width: 24vh;
}
.calendarWrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .calendarElement {
    overflow-x: auto;
  }
  .rdrMonth {
    width: 100%;
  }
}
