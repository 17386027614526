/* Define initial sidebar position (hidden) */
.sidebar-container {
  position: fixed;
  top: 0;
  left: -300px;
  bottom: 0;
  width: 250px;
  z-index: 999;
  transition: left 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Sidebar visible state */
.sidebar-container.open {
  left: 0;
}

/* Backdrop for dimming background */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  display: none; /* Hide by default */
}

.backdrop.open {
  display: block;
}

/* Sidebar content */
.sidebar {
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  background-color: #1c1e23;
  color: white;
}

.round-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}
@media (min-width: 1200px) {
  #main,
  #footer {
    margin-left: 300px;
  }
}
@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}
@media (min-width: 1200px) {
  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }
  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  transition: 0.3;
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
}
.sidebar-nav .nav-logout {
  display: flex;
  align-items: center;
  font-size: 15px;
  transition: 0.3;
  padding: 10px 15px;
  border-radius: 4px;
  color: red;
}

.sidebar-nav .nav-logout i {
  font-size: 16px;
  margin-right: 10px;
}

/* .sidebar-nav .nav-link.collapsed {
    color: #012970;
    background: #fff;
  }
  
  .sidebar-nav .nav-link.collapsed i {
    color: #899bbd;
  } */

.sidebar-nav .nav-link:hover {
  color: #4154f1;
  background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
  color: #4154f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a:active {
  color: #4154f1;
}

.sidebar-nav .nav-content a:active i {
  color: #4154f1;
}
