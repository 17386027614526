.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.otp-field {
  width: 2.5rem !important;
  height: 3rem;
  font-size: 1.2rem;
  &::-moz-selection {
    background: #1c1e23;
    color: white;
  }
  &::selection {
    background: #1c1e23;
    color: white;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.masterPage-content {
  background-color: black;
  min-height: 100vh;
  color: white;
}

.company-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.custom-border {
  border: 1px solid white;
}
.custom-border-end {
  border-right: 1px solid white;
}

.text-font1 {
  font-size: 10px;
}
.text-font2 {
  font-size: 12px;
}
.text-font3 {
  font-size: 13px;
}
.text-font4 {
  font-size: 14px;
}
.flag-dropdown {
  background-color: #1c1e23 !important;
  color: #fff;
  .selected-flag {
    background-color: #1c1e23 !important;
  }
  &:hover {
    background-color: #1c1e23 !important;
  }
  .country-list {
    background-color: #1c1e23 !important;
    .country {
      &:hover {
        background-color: #1c1e23 !important;
      }
      &.highlight {
        background-color: #1c1e23 !important;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.btn-prim {
  background-color: #f5be09;
  color: #080808;
  padding: 0.5rem;
  &:hover {
    background-color: #f5be09;
    color: #080808;
  }
  &:disabled {
    background-color: #dcab08;
    color: #080808;
  }
}
.form-control {
  &::placeholder {
    color: #a3a3a3;
  }

  &:focus {
    background-color: #1c1e23;
    color: #fff;
  }
  background-color: #1c1e23;
  border-color: #a3a3a3;
  color: #fff;
}

.form-control1 {
  background-color: #1c1e23;
  border-color: #a3a3a3;
  color: #fff;

  &::placeholder {
    color: #fff;
  }

  &:focus {
    background-color: #1c1e23;
    color: #fff;
    border-color: #fff;
  }
}

.btn-custom-light {
  background-color: #fff;
  color: #080808;
  padding: 0.5rem;
  &:hover {
    background-color: #fff;
    color: #080808;
  }
}
.custom-swal {
  background-color: #1c1e23;
  color: #fff;
}
.swal2-toast {
  background-color: #2e323a !important;
  text-align: center;
}
.card {
  background-color: #1c1e23;
  border-color: #a3a3a3;
  color: #fff;
}
