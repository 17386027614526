.modalBackground {
  color: white;
  background: #1c1e23;
  border: "1px solid #262626";
}
.modal {
  z-index: 1055 !important;
}

.modal-backdrop {
  z-index: 1050 !important;
  background-color: rgba(0, 0, 0, 0.5);
}
